import React, { useEffect, useState } from "react";
// import blogs from "../../api/blogs";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import bImg1 from "../../images/blog/2.jpg";
import bImg2 from "../../images/blog/3.jpg";
import { domain } from "../../domain";
import { blogs } from "../blogs";

const BlogSingle = (props) => {
  const { slug } = useParams();
  const [post, setPost] = useState({});
  // const [relatedPost, setRelatedPost] = useState([]);

  // const [date, setDate] = useState("");
  // const [time, setTime] = useState("");
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    // Check if the slug is present in the blogs array
    const existingPost = blogs.find((blog) => blog.slug === slug);

    if (existingPost) {
      // If the post exists in blogs array, set it directly
      console.log(existingPost);
      setPost(existingPost);
    } else {
      // Otherwise, make the API call to fetch the post
      async function getBlog() {
        try {
          const res = await fetch(`${domain}/api/post/getposts?slug=${slug}`);
          const data = await res.json();
          const fetchedPost = data.posts[0];
          // console.log(fetchedPost);

          setPost(fetchedPost);
        } catch (error) {
          console.error("Error fetching post:", error);
        }
      }
      getBlog();
    }
  }, []);

  // const BlogDetails = blogs.find((item) => item.slug === slug);

  // const submitHandler = (e) => {
  //   e.preventDefault();
  // };

  return (
    <section className="blog-single mt-150 mb-50">
      {console.log(post)}
      {/* {console.log(relatedPost)} */}
      <div className="container">
        <h3 className="blog-title">{post?.title}</h3>
        <span className="date">{new Date(post.createdAt).toDateString()}</span>
        <div className="blog-image">
          <img src={post?.image} alt="Blog" />
        </div>

        <div
          className="p-3 max-w-2xl mx-auto w-full post-content"
          dangerouslySetInnerHTML={{ __html: post.content }}
        ></div>
      </div>
    </section>
  );
};

export default BlogSingle;
